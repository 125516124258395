import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../components/layouts/secondary-landing";
import SEO from "../../components/seo/seo";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import HolidaysModal from "../../components/holidays-modal";
import ServiceStatesMapSmall from "../../components/service-states/service-states-map-sm";

import Icon from "../../components/custom-widgets/icon";
import Button from "../../components/custom-widgets/button";
import ChatSection from "../../components/contact-us/chat-section";

const ContactUs = () => {
  const title = "Contact Us";
  const description =
    "Need to contact or call WaFd Bank (Washington Federal Bank)? If you need help with your online banking, report a lost or stolen credit card, contact WaFd Bank for help.";

  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/about-us/contact-us"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      }
    ]
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/about-us",
      title: "About Us"
    },
    {
      id: 2,
      active: true,
      title: "Contact Us"
    }
  ];

  const chatSectionData = {
    title: "Let's Chat",
    description:
      "Our Walt Chatbot is always available to answer your questions about banking, help you find your local branch or find someone to talk with.",
    chatWaltButtonText: "Chat with Walt",
    chatWaltButtonId: "open-chat-walt-btn",
    customerServiceButtons: [
      {
        className: "mb-3",
        btnProps: {
          id: "contact-branch-cs-btn",
          text: "Contact a Branch",
          icon: "../../images/icons/contact-branch.svg",
          iconHover: "../../images/icons/contact-branch-hover.svg",
          url: "/locations"
        }
      },
      {
        className: "mb-3",
        btnProps: {
          id: "call-support-cs-btn",
          text: "Call Support (24/7)",
          subtext: "800-324-9375",
          icon: "../../images/icons/call-support.svg",
          iconHover: "../../images/icons/call-support-hover.svg",
          url: "tel:800-324-9375"
        }
      },
      {
        btnProps: {
          id: "email-support-cs-btn",
          text: "Email Support",
          subtext: "info@wafd.com",
          icon: "../../images/icons/email-support.svg",
          iconHover: "../../images/icons/email-support-hover.svg",
          url: "mailto:info@wafd.com"
        }
      }
    ]
  };

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container pb-0">
        <h1>WaFd Bank Customer Service</h1>
      </section>
      <ChatSection chatSectionData={chatSectionData}>
        <div className="row">
          <div className="col-12 col-md-6 order-2 order-md-1 mb-3">
            <h4>Need help with your WaFd Debit Card?</h4>
            <div className="mb-3">
              <a id="c3-help-tel-1" className="text-decoration-none" href="tel:800-324-9375">
                <Icon class="mr-2" lib="fal" name="phone-alt" />
                Call 800-324-9375
              </a>{" "}
              if you lost your debit card
            </div>
            <div>
              <a id="pin-help-tel" className="text-decoration-none" href="tel:866-661-8550">
                <Icon class="mr-2" lib="fal" name="phone-alt" />
                Call 866-661-8550
              </a>{" "}
              if you forgot your PIN
            </div>
          </div>
          <div className="col-12 col-md-6 order-1 order-md-2 mb-3">
            <div className="mb-3">
              For international callers, please call{" "}
              <a id="international-help-tel" href="tel:1-206-624-7930">
                1-206-624-7930
              </a>
              .
            </div>
            <div className="font-weight-bold text-green-60">
              WaFd Bank customer support is available 24 hours a day 7 days a week.
            </div>
          </div>
        </div>
      </ChatSection>
      <section className="bg-light">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <StaticImage
                className="mb-4"
                src="../../images/wafd-voice-activated-banking-700.jpg"
                alt="Happy mature woman talking on the phone and smiling"
                placeholder="blurred"
                quality="100"
              />
            </div>
            <div className="col-lg-6">
              <h2>Voice-Activated Banking</h2>
              <p className="text-green-60 font-weight-semi-bold">Signing up is easy:</p>
              <ol className="mb-3">
                <li>If you haven't already, sign up for consumer online or mobile banking.</li>
                <li>
                  Next, call{" "}
                  <a id="c3-help-tel-2" href="tel:800-324-9375">
                    800-324-9375
                  </a>{" "}
                  to set up your Voice-Activated Banking profile - you'll then be guided to record your voice, so our
                  service can authenticate you going forward.
                </li>
                <li>
                  Anytime you call in, the service will ask you to authenticate using your voice. Once authenticated the
                  service will say, "Hi, how can I help you today?" and then offers suggestions including provide
                  balance, transfer, or talk to an agent.
                </li>
              </ol>
              <Button
                id="learn-voice-activated-banking-btn"
                text="Learn about Voice-Activated Banking"
                showIcon={false}
                type="link"
                url="/personal-banking/voice-recognition-technology"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-success">
        <div className="container">
          <div className="row">
            <div className="col-auto">
              <img src="/images/icons/home-owner-assistance-icon-128.svg" alt="" style={{ width: "102px" }} />
            </div>
            <div className="col">
              <h2 className="text-white">Are you a homeowner having difficulties?</h2>
              <Link
                id="homeowner-assistance-link"
                className="text-white hybrid-link h5 font-weight-bold text-decoration-none"
                to="/personal-banking/home-loans/homeowner-assistance"
              >
                Learn about Homeowner Assistance
                <Icon class="ml-1" lib="fas" name="arrow-right" />
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="container" id="holidays">
        <h3>Our branches are closed on the following Federal Holidays:</h3>
        <HolidaysModal />
      </section>
      <section className="container">
        <div className="row">
          <div className="col-md-6">
            <h2>Over 200 offices to serve you.</h2>
            <p>
              From{" "}
              <Link id="services-link-1" to="/personal-banking/checking-account">
                checking
              </Link>
              ,{" "}
              <Link id="services-link-2" to="/personal-banking/savings-account">
                savings
              </Link>{" "}
              and{" "}
              <Link id="services-link-3" to="/personal-banking/retirement-ira-accounts">
                retirement accounts
              </Link>{" "}
              to {""}
              <Link id="services-link-4" to="/personal-banking/home-loans">
                home loans
              </Link>{" "}
              and{" "}
              <Link id="services-link-5" to="/commercial-banking/commercial-real-estate-loans">
                corporate real estate financing
              </Link>
              , WaFd Bank is ready to help bring clarity to banking. We operate in nine western states, including{" "}
              <Link id="wafd-states-link-arizona" to="/locations/arizona">
                Arizona
              </Link>
              ,{" "}
              <Link id="wafd-states-link-california" to="/locations/california">
                California
              </Link>
              ,{" "}
              <Link id="wafd-states-link-idaho" to="/locations/idaho">
                Idaho
              </Link>
              ,{" "}
              <Link id="wafd-states-link-nevada" to="/locations/nevada">
                Nevada
              </Link>
              ,{" "}
              <Link id="wafd-states-link-new-mexico" to="/locations/new-mexico">
                New Mexico
              </Link>
              ,{" "}
              <Link id="wafd-states-link-oregon" to="/locations/oregon">
                Oregon
              </Link>
              ,{" "}
              <Link id="wafd-states-link-texas" to="/locations/texas">
                Texas
              </Link>
              ,{" "}
              <Link id="wafd-states-link-utah" to="/locations/utah">
                Utah
              </Link>
              , and{" "}
              <Link id="wafd-states-link-washington" to="/locations/washington">
                Washington
              </Link>
              .
            </p>
            <p>
              Our common-sense, disciplined approach has stood the test of time and made us one of the strongest
              financial institutions in America. Find a{" "}
              <Link id="services-locations-link" to="/locations">
                location
              </Link>{" "}
              near you.
            </p>
          </div>
          <div className="col-md-6">
            <ServiceStatesMapSmall />
          </div>
        </div>
      </section>
    </SecondaryLanding>
  );
};

export default ContactUs;
